import React from 'react'
import { Link } from 'react-router-dom';

function Features() {
    return (
        <>
            <section class="bg-half-170 bg-light d-table w-100">
                <div class="container">
                    <div class="row mt-5 justify-content-center">
                        <div class="col-lg-12 text-center">
                            <div class="pages-heading">
                                <h4 class="title mb-0"> Features </h4>
                            </div>
                        </div>
                    </div>
                    <div class="position-breadcrumb">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                                <li class="breadcrumb-item">
                                    <Link to="/">Gathrr</Link></li>
                                <li class="breadcrumb-item active" aria-current="page">Features</li>
                            </ul>
                        </nav>
                    </div>
                </div>         </section>
            <div class="position-relative">
                <div class="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section class="section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4 col-12 mt-5">
                            <div class="features feature-primary">
                                <div class="image position-relative d-inline-block">
                                    <i class="uil uil-edit-alt h2 text-primary"></i>
                                </div>

                                <div class="content mt-4">
                                    <h5>Attendee Check-In</h5>
                                    <p class="text-muted mb-0">Ditch your paper spreadsheets and check-in attendees with one easy click. You no longer have to watch a long line of attendees at the registration desk.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-12 mt-5">
                            <div class="features feature-primary">
                                <div class="image position-relative d-inline-block">
                                    <i class="uil uil-vector-square h2 text-primary"></i>
                                </div>

                                <div class="content mt-4">
                                    <h5>Easy Event Marketing</h5>
                                    <p class="text-muted mb-0">Get maximum engagement by sharing the event with inbuilt push notifications feature in the Gathrr app dashboard. It's the best feature to get maximum RSVP's in advance.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 mt-5">
                            <div class="features feature-primary">
                                <div class="image position-relative d-inline-block">
                                    <i class="uil uil-file-search-alt h2 text-primary"></i>
                                </div>

                                <div class="content mt-4">
                                    <h5>Name Badge Generation</h5>
                                    <p class="text-muted mb-0">Making high-end, professional-looking name badges has never been easier! Simply review and print what we designed for you. It’s one click on your Gathrr mobile app!.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-4 col-12 mt-5">
                            <div class="features feature-primary">
                                <div class="image position-relative d-inline-block">
                                    <i class="uil uil-airplay h2 text-primary"></i>
                                </div>

                                <div class="content mt-4">
                                    <h5>Post Event Networking</h5>
                                    <p class="text-muted mb-0">Save 60% of your time by meeting the people who actually matter after the event. Virtual Business cards will soon make the thick paper Business Cards obsolete.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 mt-5">
                            <div class="features feature-primary">
                                <div class="image position-relative d-inline-block">
                                    <i class="uil uil-calendar-alt h2 text-primary"></i>
                                </div>

                                <div class="content mt-4">
                                    <h5>Live Event Polling</h5>
                                    <p class="text-muted mb-0">Effortlessly gather votes and get an accurate count! Attendees can vote through the Gathrr app. Use template questions that have made so many events engaging!</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-12 mt-5">
                            <div class="features feature-primary">
                                <div class="image position-relative d-inline-block">
                                    <i class="uil uil-clock h2 text-primary"></i>
                                </div>

                                <div class="content mt-4">
                                    <h5>Post Event Feed back</h5>
                                    <p class="text-muted mb-0">How do you measure your event success? See everything about your event, from insights to detailed statistics, with one glance on the Gathrr app dashboard.</p>
                                </div>
                            </div>
                        </div>                </div>            </div>
                <div class="container mt-100 mt-60">
                    <div class="row justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title">
                                <h4 class="title mb-4">Enterprise-grade guest management features for event producers, IT professionals, brand managers, and more.</h4>
                                <p class="text-muted para-desc mx-auto">All event data lives in one place, enabling more insight sharing across your team, more collaboration on guest experience, and more ROI from your events.</p>

                                <div class="mt-4">
                                    <Link to="/pricing" className="btn btn-primary mt-2 me-2">Get Started Now
                                    </Link>
                                    <Link to="/pricing" className="btn btn-outline-primary mt-2">Free Trial
                                    </Link>

                                </div>
                            </div>
                        </div>                </div>
                </div>        </section>
        </>
    )
}

export default Features
