
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faUser, faEnvelope, faContactCard, faFile, faMap, faFileText, faIdCard, faComment } from '@fortawesome/free-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
function ContactUs() {
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        company: '',
        location: '',
        subject: '',
        comment: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (e.target.checkValidity()) {
            console.log(formData);

            try {
                const response = await fetch('https://gathrr.radarsofttech.in:5050/api/enquiry', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                });

                const data = await response.json();

                if (response.ok) {
                    setSuccessMessage('Form submitted successfully');
                    setFormData({
                        fname: '',
                        lname: '',
                        email: '',
                        phone: '',
                        company: '',
                        location: '',
                        subject: '',
                        comment: ''
                    });
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 2000);
                } else {
                    setErrorMessage('Failed to submit form.');
                }
            } catch (error) {
                console.log(error);
                setErrorMessage('Failed to submit form.');
                setTimeout(() => {
                    setErrorMessage('');
                }, 2000);
            }
        } else {

            setErrorMessage('Please fill out all required fields correctly.');
            setTimeout(() => {
                setErrorMessage('');
            }, 2000);
        }
        navigate('/contact')
    };


    return (
        <>
            <section className="bg-half-170 bg-light d-table w-100" style={{ background: 'assets/images/contact-detail.jpg' }}>
                <div className="bg-overlay bg-overlay-white"></div>
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading">
                                <h4 className="title mb-0">Contact Us</h4>
                            </div>
                        </div>
                    </div>

                    <div className="position-breadcrumb">
                        <nav aria-label="breadcrumb" className="d-inline-block">
                            <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                <li className="breadcrumb-item">
                                    <Link to="/" className="text-foot">Gathrr</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>

            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6  mt-4 pt-2">
                            <div className="card shadow rounded border-0">
                                <div className="card-body py-5">
                                    <h4 className="card-title">Get In Touch !</h4>
                                    <div className="custom-form mt-3">
                                        {successMessage && <div className="alert alert-primary">{successMessage}</div>}
                                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

                                        <form onSubmit={handleSubmit} noValidate>
                                            <p id="error-msg" className="mb-0"></p>
                                            <div id="simple-msg"></div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">First Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative text-center">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faUser} />
                                                            <input
                                                                name="fname"
                                                                id="fname"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="First Name"
                                                                required=""
                                                                value={formData.fname}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faUser} />
                                                            <input
                                                                name="lname"
                                                                id="lname"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Last Name"
                                                                required=""
                                                                value={formData.lname}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label"> Email <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faEnvelope} />
                                                            <input
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                className="form-control ps-5"
                                                                placeholder="Email"
                                                                required=""
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Phone <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faContactCard} />
                                                            <input
                                                                name="phone"
                                                                id="phone"
                                                                type="number"
                                                                className="form-control ps-5"
                                                                placeholder="Phone"
                                                                required=""
                                                                value={formData.phone}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Company Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faIdCard} />
                                                            <input
                                                                name="companyName"
                                                                id="companyName"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Company Name"
                                                                required=""
                                                                value={formData.company}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Location <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faMap} />
                                                            <input
                                                                name="location"
                                                                id="location"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Location"
                                                                required=""
                                                                value={formData.location}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Subject <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faFileText} />
                                                            <input
                                                                name="subject"
                                                                id="subject"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Subject"
                                                                required=""
                                                                value={formData.subject}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Comments </label>
                                                        <div className="form-icon position-relative">
                                                            <FontAwesomeIcon className=" icon-sm icons" icon={faComment} />
                                                            <input
                                                                name="comment"
                                                                id="comment"
                                                                type="textarea"
                                                                className="form-control ps-5"
                                                                placeholder="Comments"
                                                                required=""
                                                                value={formData.comment}
                                                                onChange={handleChange}
                                                                style={{ paddingLeft: '0' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-grid">
                                                        <button
                                                            type="submit"
                                                            id="submit"
                                                            name="senddata"
                                                            className="btn btn-primary"
                                                        >
                                                            Send Message
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12 ps-md-3 mt-4 pt-2">
                            <div className="card map map-height-two rounded map-gray border-0">
                                <div className="card-body p-0">
                                    <iframe
                                        style={{ width: '100% !important' }}
                                        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Gathrr&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                        className="rounded"
                                        allowFullScreen=""
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-100 mt-60">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card border-0 text-center features feature-primary feature-clean">
                                <div className="icons text-center mx-auto">
                                    <i className="uil d-block rounded h3 mb-0"> <FontAwesomeIcon icon={faPhone} />
                                    </i>
                                </div>
                                <div className="content mt-4">
                                    <h5 >Phone</h5>

                                    <a href="tel:+91 954 512 6030" className="read-more">+91 954 512 6030</a><br></br>
                                    <a href="tel:+91 895 609 2220" className="read-more">+91 895 609 2220</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="card border-0 text-center features feature-primary feature-clean">
                                <div className="icons text-center mx-auto">
                                    <i className="uil d-block rounded h3 mb-0"><FontAwesomeIcon icon={faMailBulk} /></i>
                                </div>
                                <div className="content mt-4">
                                    <h5 className="fw-bold">Email</h5>
                                    <a href="mailto:jeet@gathrr.in" className="read-more">jeet@gathrr.in</a>
                                    <div></div>
                                    <a href="mailto:info@gathrr.in" className="read-more">info@gathrr.in</a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="card border-0 text-center features feature-primary feature-clean">
                                <div className="icons mx-auto">
                                    <i className="uil d-block rounded h3 mb-0"><FontAwesomeIcon icon={faLocation} /></i>
                                </div>
                                <div className="content mt-4">
                                    <h5 className="fw-bold">Location</h5>
                                    <p className="text-muted">COEP's BHAU Institute Shivajinagar, Pune.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactUs
