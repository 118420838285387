import { React, useState, useEffect} from 'react'
import { Link } from 'react-router-dom';

function Pricing() {
    const [isYearly, setIsYearly] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
  
           return () => clearTimeout(timer);
    }, []);
    const handleToggle = () => {
        setIsYearly(!isYearly);
    };
    return (
        <>
        
            <section class="bg-half-170 bg-light d-table w-100">
                <div class="container">
                    <div class="row mt-5 justify-content-center">
                        <div class="col-lg-12 text-center">
                            <div class="pages-heading">
                                <h4 class="title mb-0"> Pricing </h4>
                            </div>
                        </div>                  </div>
                    <div class="position-breadcrumb">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb rounded shadow mb-0 px-4 py-2">
                                <li class="breadcrumb-item"><a href="index.php">Gathrr</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Pricing</li>
                            </ul>
                        </nav>
                    </div>
                </div>        </section>

            <div class="position-relative">
                <div class="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>


            <section className="section">
                <div className="container mt-10">
                    <div className="switcher-pricing d-flex justify-content-center align-items-center mb-4 pb-2">
                        <label
                            className={`toggler text-muted ${!isYearly ? 'toggler--is-active' : ''}`}
                            id="filt-monthly"
                            onClick={() => setIsYearly(false)}
                        >
                            INDIA
                        </label>
                        <div className="form-check form-switch mx-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="switcher"
                                checked={isYearly}
                                onChange={handleToggle}
                            />
                        </div>
                        <label
                            className={`toggler text-muted ${isYearly ? 'toggler--is-active' : ''}`}
                            id="filt-yearly"
                            onClick={() => setIsYearly(true)}
                        >
                            USD
                        </label>
                    </div>

                    {isYearly ? (
                        <div class="row">

                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">Starter</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2"></span>
                                            <span class="price h1 mb-0">Free</span>
                                            <span class="h4 align-self-end mb-1"></span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Beautiful Event Pages</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>DIY Event Listing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Registrations up to 50 attendees</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Networking</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Speaker's profile</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee QR based check-in</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free event registratons</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Gathrr Branding</li>
                                        </ul>
                                        <Link to="/contact" className="btn btn-primary mt-4">Get Started
                                        </Link>


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">Basic</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2">$</span>
                                            <span class="price h1 mb-0">49</span>
                                            <span class="h4 align-self-end mb-1">/mo</span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Beautiful Event Pages</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>DIY Event Listing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Registrations up to 100 attendees</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Networking</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Speaker's profile</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>WhatsApp confirmations</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Agenda</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Sponsors page</li>


                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee QR based check-in</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Smart digital business cards</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Custom notifications up to 10/mo</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Paid registrations</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Gathrr Branding</li>

                                        </ul>
                                        <Link to="/contact" className="btn btn-primary mt-4">Get Started
                                        </Link>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">Advanced</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2">$</span>
                                            <span class="price h1 mb-0">99</span>
                                            <span class="h4 align-self-end mb-1">/mo</span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Beautiful Event Pages</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>DIY Event Listing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Registrations up to 100 attendees</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Networking</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Speaker's profile</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>WhatsApp confirmations</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Agenda</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Sponsors page</li>


                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee QR based check-in</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Smart digital business cards</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Custom notifications up to 10/mo</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Paid registrations</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Agenda bookmarking</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Agenda reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee event reports</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Live Polls and Q&A</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event feedback</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Exhibitors profile and lead capturing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Gathrr Branding</li>
                                        </ul>
                                        <Link to="/contact" className="btn btn-primary mt-4">Get Started
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">White Labelled</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2"></span>
                                            <span class="price h1 mb-0"></span>
                                            <span class="h4 align-self-end mb-0"></span>
                                        </div>

                                        <ul class="list-unstyled mb-0 mt-20 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Multiuser Platformwith user management</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Unlimited Everything</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Service Level Agreements (SLA)</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Custom Domain</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>White Labeling</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Priority Real-Time Support</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Local Data Residency Option</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Single Sign-On (SSO) Integrations</li>
                                        </ul>

                                        <Link to="/contact" className="btn btn-primary mt-4">Contact Sales
                                        </Link>

                                    </div>
                                </div>
                            </div>
                        </div>


                    ) : (
                        <div class="row">

                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">Starter</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2"></span>
                                            <span class="price h1 mb-0">Free</span>
                                            <span class="h4 align-self-end mb-1"></span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Beautiful Event Pages</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>DIY Event Listing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Registrations up to 50 attendees</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Networking</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Speaker's profile</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee QR based check-in</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Free event registratons</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Gathrr Branding</li>
                                        </ul>

                                        <Link to="/contact" className="btn btn-primary mt-4">Get Started
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">Basic</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2">&#8377; </span>
                                            <span class="price h1 mb-0">1799</span>
                                            <span class="h4 align-self-end mb-1">/mo</span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Beautiful Event Pages</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>DIY Event Listing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Registrations up to 100 attendees</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Networking</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Speaker's profile</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>WhatsApp confirmations</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Agenda</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Sponsors page</li>


                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee QR based check-in</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Smart digital business cards</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Custom notifications up to 10/mo</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Paid registrations</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Gathrr Branding</li>

                                        </ul>

                                        <Link to="/contact" className="btn btn-primary mt-4">Get Started
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">Advanced</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2">&#8377; </span>
                                            <span class="price h1 mb-0">3499</span>
                                            <span class="h4 align-self-end mb-1">/mo</span>
                                        </div>

                                        <ul class="list-unstyled mb-0 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Beautiful Event Pages</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>DIY Event Listing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Registrations up to 100 attendees</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Networking</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Speaker's profile</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>WhatsApp confirmations</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event Agenda</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Sponsors page</li>


                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee QR based check-in</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Smart digital business cards</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Custom notifications up to 10/mo</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Paid registrations</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Agenda bookmarking</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Agenda reminders</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Attendee event reports</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Live Polls and Q&A</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Event feedback</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Exhibitors profile and lead capturing</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Gathrr Branding</li>
                                        </ul>
                                        <Link to="/contact" className="btn btn-primary mt-4">Get Started
                                        </Link>


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                                <div class="card pricing pricing-primary business-rate shadow bg-light border-0 rounded">
                                    <div class="card-body">
                                        <h6 class="title name fw-bold text-uppercase mb-4">White Labelled</h6>
                                        <div class="d-flex mb-4">
                                            <span class="h4 mb-0 mt-2"></span>
                                            <span class="price h1 mb-0"></span>
                                            <span class="h4 align-self-end mb-0"></span>
                                        </div>

                                        <ul class="list-unstyled mb-0 mt-20 ps-0">
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Multiuser Platformwith user management</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Unlimited Everything</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Service Level Agreements (SLA)</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Custom Domain</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>White Labeling</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Priority Real-Time Support</li>

                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Local Data Residency Option</li>
                                            <li class="h6 text-muted mb-0"><span class="icon h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Single Sign-On (SSO) Integrations</li>
                                        </ul>

                                        <Link to="/contact" className="btn btn-primary mt-4">Get Started
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section class="section bg-light">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-12">
                            <div class="d-flex">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
                                <div class="flex-1">
                                    <h5 class="mt-0">How does <span class="text-primary">Gathrr</span> work ?</h5>
                                    <p class="answer text-muted mb-0">Chose one of our pricing plans that suits you, get your access to the dashboard, create events, add sponsors, track attendees, personalise event sessions and agendas, and send custom notifications to attendees and keep them updated. Gathrr will sync all your data entered from the dashboard to the Gathrr app.<a class="" href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"> Request a call? </a> </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div class="d-flex">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
                                <div class="flex-1">
                                    <h5 class="mt-0">How long will I have the access to the data for my past events ?</h5>
                                    <p class="answer text-muted mb-0">Your data from the past events will be visible and can be downloaded until the subscription ends. All the data will be available and lifetime accessible to your attendees on the Gathrr app.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="d-flex">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
                                <div class="flex-1">
                                    <h5 class="mt-0">How am I going to make the attendees download the app ?</h5>
                                    <p class="answer text-muted mb-0">Your attendees can get registered either via app or the dashboard. Scheduled emails with Gathrr app features (one click registration, check-in, networking & much more) are delivered. And hey, the Gathrr app is completely Free! </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6 col-12 mt-4 pt-2">
                            <div class="d-flex">
                                <i data-feather="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i>
                                <div class="flex-1">
                                    <h5 class="mt-0"> Is the app free for attendees to download ?</h5>
                                    <p class="answer text-muted mb-0">Yes, event attendees get to use all the app features and download the app for Free.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
                        <div class="col-12 text-center">
                            <div class="section-title">
                                <h4 class="title mb-4">Have Question ? Get in touch!</h4>
                                <p class="text-muted para-desc mx-auto">Connect with a <span class="text-primary fw-bold">Gathrr </span> expert to show you all the event management software features you want to see. </p>
                                <div class="mt-4 pt-2">

                                    <Link to="/contact" className="btn btn-primary mt-4">Contact us <i className="mdi mdi-arrow-right"></i>
                                    </Link>

                                </div>
                            </div>
                        </div>                </div>
                </div>
            </section>
        </>
    )
}

export default Pricing
