import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFeather, faDesktop, faEye, faUserCheck, faMobileAndroid, faHeart } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';


function Home() {

  const [cSelected, setCSelected] = useState([]);
  const [rSelected, setRSelected] = useState(null);
  const [activeTab, setActiveTab] = useState('pills-cloud');


  const onCheckboxBtnClick = (selected) => {
    const index = cSelected.indexOf(selected);
    if (index < 0) {
      cSelected.push(selected);
    } else {
      cSelected.splice(index, 1);
    }
    setCSelected([...cSelected]);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };


  const styles = {

    headingh1: {
      fontSize: '15 px',
    },
    btnc: {
      backgroundColor: '#FF5349',
      borderColor: '#FF5349',
    },
    btncHover: {
      backgroundColor: '#FF2316',
      borderColor: '#FF2316',
    },
    imgnew: {
      maxWidth: '120%',
    },
    fimags: {
      maxWidth: '80%',
    },
    fimags1: {
      maxWidth: '70%',
    },
    imgfluid: {
      maxWidth: '70%',
    },
    hideDiv: {
      display: 'none',
    },
    
  };
  return (

    <>
      <section className="bg-half-170 d-table w-100" id="home">
        <div className="container">
          <div className="row mt-5 align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="title-heading">
               <h1 className=" mb-3" style={{fontSize:'20px'}}>
               The Best Event Management Software For B2B Events 
               </h1>
                <h4 style={{fontSize:'35px'}} className=" mb-3">A powerful tech is what every <span className="text-primary">Event </span> needs</h4>
                <p className="para-desc text-muted">Discover the Power of Our Cutting-Edge Event Management Software- Streamline Planning, Boost Efficiency, and Captivate Your Audience! Whether you're an event planner, corporate team, or venue owner, our advanced software simplifies the entire event lifecycle. So Gathrr offers a digital approach to our physical event experience.</p>
                <div className="mt-4">
                  <a href="https://apps.apple.com/in/app/gathrr/id1498313428" target="_blank" className="btn btn-primary mt-2 me-2"><i className="uil uil-apple"></i> App Store</a>
                  <a href="https://play.google.com/store/apps/details?id=com.gathrr.radarsoft&hl=en&gl=US" target="_blank" className="btn btn-outline-primary mt-2 me-2"><i className="uil uil-google-play"></i> Play Store</a>
                  <a className="btn btn-danger mt-2 btnc" href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i className="uil uil-user"></i> Free Demo</a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0 hide-div">
              <div className="text-md-end text-center ms-lg-4">
                <img src="assets/images/app/homes.png" className="img-fluid imgnew" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative">
        <div className="shape overflow-hidden text-light">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>

      <section className="section bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4">App Features</h4>
                <h2 className="text-muted para-desc mb-0 mx-auto" style={{fontSize:'20px'}}> Take Control of Your Events with Our Feature-Packed Event Planning Software </h2>
              </div>
            </div>
          </div>

          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 col-md-8">
              <div className="row mt-4 pt-2">
                <div className="col-md-6 col-12">
                  <div className="d-flex features feature-primary pt-4 pb-4">
                    <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                      <FontAwesomeIcon icon={faDesktop} />
                    </div>
                    <div className="flex-1">
                      <h4 className="title">Attendee Check-In</h4>
                      <p className="text-muted para mb-0">Ditch your paper spreadsheets and check-in attendees with one easy click. You no longer have to watch a long line of attendees at the registration desk.</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="d-flex features feature-primary pt-4 pb-4">
                    <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                      <FontAwesomeIcon icon={faFeather} />
                    </div>
                    <div className="flex-1">
                      <h4 className="title">Easy Event Marketing</h4>
                      <p className="text-muted para mb-0">Get maximum engagement by sharing the event with inbuilt push notifications feature in the Gathrr app dashboard. It's the best feature to get maximum RSVP's in advance.</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="d-flex features feature-primary pt-4 pb-4">
                    <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                      <FontAwesomeIcon icon={faEye} />
                    </div>
                    <div className="flex-1">
                      <h4 className="title">Name Badge Generation</h4>
                      <p className="text-muted para mb-0">Making high-end, professional-looking name badges has never been easier! Simply review and print what we designed for you. It’s one click on your Gathrr mobile app!.</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="d-flex features feature-primary pt-4 pb-4">
                    <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                      <FontAwesomeIcon icon={faUserCheck} />
                    </div>
                    <div className="flex-1">
                      <h4 className="title">Post Event Networking</h4>
                      <p className="text-muted para mb-0">Save 60% of your time by meeting the people who actually matter after the event. Virtual Business cards will soon make the thick paper Business Cards obsolete.</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="d-flex features feature-primary pt-4 pb-4">
                    <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                      <FontAwesomeIcon icon={faMobileAndroid} />
                    </div>
                    <div className="flex-1">
                      <h4 className="title">Live Event Polling</h4>
                      <p className="text-muted para mb-0">Effortlessly gather votes and get an accurate count! Attendees can vote through the Gathrr app. Use template questions that have made so many events engaging!</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="d-flex features feature-primary pt-4 pb-4">
                    <div className="icon text-center rounded-circle text-primary me-3 mt-2">
                      <FontAwesomeIcon icon={faHeart} />
                    </div>
                    <div className="flex-1">
                      <h4 className="title">Post Event Feed back</h4>
                      <p className="text-muted para mb-0">How do you measure your event success? See everything about your event, from insights to detailed statistics, with one glance on the Gathrr app dashboard.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
              <img src="assets/images/app/0001.png" style={{maxWidth:'320px'}} className="img-fluid fimags" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="section pt-0 bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4">How Can We Help You ?</h4>
                <h5 className="text-muted para-desc mb-0 mx-auto">Launch your event with us and gain unbelievable attendee <span className="text-primary fw-bold">engagement</span> and <span className="text-primary fw-bold">feedback.</span></h5>
              </div>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 mt-4 pt-2 hide-div">
              <img src="assets/images/app/0002.png" style={{maxWidth:'320px'}} className="img-fluid mx-auto d-block fimags1" alt="" />
            </div>

            <div className="col-lg-7 col-md-6 mt-4 pt-2">
              <div className="section-title ms-lg-5">
                <h4 className="title mb-4" style={{ fontSize: '29px!important' }}>A Small Step Towards Your Event <span className="text-primary">Success.</span></h4>
                <h3 className="text-muted" style={{fontSize:'20px'}}>Smarter, Faster, Better: Transform Your Events with Planning Software</h3>
                <p className="text-muted">Spend less time stuck in spreadsheets. Create, edit, and curate effortlessly for any event, whether it’s digital, in-person, or a combination of the two.</p>
                <ul className="list-unstyled text-muted">
                  <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Capture payments and take registrations in real-time</li>
                  <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Get event analytics and maintain event data at centralized repository</li>
                  <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Keep attendees up to date with real-time engagement tool integration</li>
                </ul>
                <Link to="/pricing" className="mt-3 h6 text-primary">
                  Find Out More <i className="uil uil-angle-right-b"></i>
                </Link>
              </div>
            </div>



          </div>
        </div>
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-4 pb-2">
                <h4 className="title mb-4"><span className="text-primary">Gathrr</span> App Showcase</h4>
                <p className="text-muted para-desc mb-0 mx-auto"><span className="text-primary fw-bold">Contactless </span> and <span className="text-primary fw-bold">QR code-enabled </span> to minimize risks and keep your guests and staff safe and socially distanced.</p>
              </div>
            </div>
          </div>


          <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12 mt-4 pt-2 text-center">
              <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded" id="pills-tab" role="tablist">
                <li className="nav-item">
                  <a className={`nav-link rounded ${activeTab === 'pills-cloud' ? 'active' : ''}`} id="pills-cloud-tab" data-bs-toggle="pill" href="#pills-cloud" role="tab" aria-controls="pills-cloud" aria-selected={activeTab === 'pills-cloud' ? 'true' : 'false'} onClick={() => handleTabClick('pills-cloud')}>
                    <div className="text-center py-2">
                      Sponsor
                    </div>
                  </a>
                </li>

                <li className="nav-item">
                  <a className={`nav-link rounded ${activeTab === 'pills-smart' ? 'active' : ''}`} id="pills-smart-tab" data-bs-toggle="pill" href="#pills-smart" role="tab" aria-controls="pills-smart" aria-selected={activeTab === 'pills-smart' ? 'true' : 'false'} onClick={() => handleTabClick('pills-smart')}>
                    <div className="text-center py-2">
                      <h6 className="mb-0">Exhibitors</h6>
                    </div>
                  </a>
                </li>

                <li className="nav-item">
                  <a className={`nav-link rounded ${activeTab === 'pills-apps' ? 'active' : ''}`} id="pills-apps-tab" data-bs-toggle="pill" href="#pills-apps" role="tab" aria-controls="pills-apps" aria-selected={activeTab === 'pills-apps' ? 'true' : 'false'} onClick={() => handleTabClick('pills-apps')}>
                    <div className="text-center py-2">
                      <h6 className="mb-0">Personal Schedule</h6>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4 pt-2">
              <div className="tab-content" id="pills-tabContent">
                <div className={`tab-pane fade ${activeTab === 'pills-cloud' ? 'show active' : ''}`} id="pills-cloud" role="tabpanel" aria-labelledby="pills-cloud-tab">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <img src="assets/images/app/2.png" style={{maxWidth:'320px'}} className="img-fluid mx-auto d-block imgfluid" alt="" />
                    </div>

                    <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4"><i className="uil uil-angle-double-right text-primary"></i>Sponsors</h4>
                        <p className="text-muted">View types of sponsors, visit their websites! Endorsements, Sponsorships, Attendees are nine times more likely to land at your next event. </p>
                        <ul className="list-unstyled text-muted">
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Give better sponsor visibility</li>
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Retain better sponsors for next events</li>
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Increase your event and sponsor's ROI</li>
                        </ul>
                        <Link to="/pricing" className="mt-3 h6 text-primary">
                          Find Out More <i className="uil uil-angle-right-b"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`tab-pane fade ${activeTab === 'pills-smart' ? 'show active' : ''}`} id="pills-smart" role="tabpanel" aria-labelledby="pills-smart-tab">
                  <div className="row align-items-center">
                    <div className="col-md-6 ">
                      <img src="assets/images/app/3.png" style={{maxWidth:'320px'}} className="img-fluid mx-auto d-block imgfluid" alt="" />
                    </div>

                    <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4"><i className="uil uil-angle-double-right text-primary"></i> Exhibitors</h4>
                        <p className="text-muted">Browse & search exhibitors, products, contact the brands, download their brochures, connect with them and more.</p>
                        <ul className="list-unstyled text-muted">
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Individual exhibitor's profile</li>
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Digital brochures and products listing</li>
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>One-click call to action button to capture easy leads</li>
                        </ul>
                        <Link to="/pricing" className="mt-3 h6 text-primary">
                          Find Out More <i className="uil uil-angle-right-b"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={`tab-pane fade ${activeTab === 'pills-apps' ? 'show active' : ''}`} id="pills-apps" role="tabpanel" aria-labelledby="pills-apps-tab">
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <img src="assets/images/app/4.png" style={{maxWidth:'320px'}} className="img-fluid mx-auto d-block imgfluid" alt="" />
                    </div>

                    <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <div className="section-title">
                        <h4 className="title mb-4"><i className="uil uil-angle-double-right text-primary"></i> Personal Schedule</h4>
                        <p className="text-muted">Allow attendees to personalize their schedule and set reminders. Need more interaction in your event? Your attendees can actively participate in each session & answer live polling in between.</p>
                        <ul className="list-unstyled text-muted">
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Browse through agenda</li>
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Bookmark your favorite schedule</li>
                          <li className="mb-1"><span className="text-primary h5 me-2"><i className="uil uil-check-circle align-middle"></i></span>Get custom notifications for bookmarked agendas</li>
                        </ul>
                        <Link to="/pricing" className="mt-3 h6 text-primary">
                          Find Out More <i className="uil uil-angle-right-b"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
          </svg>
        </div>
      </div>
    </>
  );
}

export default Home;
