import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardCheck, faPeopleGroup,faDesktop, faSquarePollVertical,faRectangleAd, faIdBadge,faMessage,faUserCheck, faMobileAndroid, faHeart } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
function AboutUs() {
    return (
        <>
            <section className="bg-half-170 bg-light d-table w-100">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-lg-12 text-center">
                            <div className="pages-heading">
                                <h4 className="title mb-0"> About us </h4>
                            </div>
                        </div>
                    </div>

                    <div className="position-breadcrumb">
                        <nav aria-label="breadcrumb" className="d-inline-block">
                            <ul className="breadcrumb rounded shadow mb-0 px-4 py-2">
                                <li className="breadcrumb-item">
                                <Link to="/" >
                                Gathrr
                </Link>
                
                                </li>

                                <li className="breadcrumb-item active" aria-current="page">About Us</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>



            <div className="position-relative">
                <div className="shape overflow-hidden text-color-white">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>



            <section className="section">
                <div className="container">
                    <div className="row align-items-center">


                        <div className="col-lg-12 col-md-12 mt-4 pt-2 mt-sm-0 pt-sm-0">
                            <div className="section-title ms-lg-4">
                                <h4 className="title mb-4">With Gathrr</h4>
                                <p className="text-muted">Hyper catalyse your ability to react in time and get the best out of your event ensuring higher profitability. Knowing the future of your events in advance by getting statistics of current events, be able to retain over 80% of your past attendees, exhibitors and sponsors! </p>



                                <p className="title mb-4" style={{fontSize:'16px !important'}}><strong>WE ARE INTO EVENT TECH AND WE LOVE A CHALLENGE.</strong></p>

                                <p className="text-muted">Gathrr is a smart product innovation firm that solves real world event problems with technology. Gathrr is one such solution that helps events  find their missing piece and nurture the right event path across all parameters and vectors. Tell us about your event, we’ll make it happen for you! </p>

                                <p className="title mb-4" style={{fontSize:'16px !important'}}><strong>KNOW YOUR EVENT IN AND OUT. SUCCEED. REPEAT.</strong></p>

                                <p className="text-muted"> Discover your event key points, identify gaps, get up-skilling suggestions by us in “Gathrr Event Catalogue” and make your event great.And then you do it again.</p>

                                <p className="title mb-4" style={{fontSize:'16px !important'}}><strong>THE SUM OF YOUR ALL YOUR EVENT DATA YOU GET</strong></p>

                                <p className="text-muted">Endorsements, Sponsorships, Attendees are nine times more likely to land at your next event. Tracking your event is helpful to ensure you land the right opportunity always. You continuously improve and climb.</p>

                                <p className="title mb-4" style={{ fontSize: '16px !important' }}><strong>Pay just to create your EVENT.<br></br> Rest is free!</strong></p>

                                <p className="text-muted">“Human behaviour can be manipulated or inspired. It’s a choice as an event organiser you make. Not making that choice is also a choice you’re making.”
                                    ~ Gathrr Team. </p>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-100 mt-60">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">Key Features</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Start working with <span className="text-primary fw-bold">Gathrr</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                    <div className="icon text-center rounded-circle me-3">
                                    <FontAwesomeIcon icon={faClipboardCheck} />
                </div>
                                    <div className="flex-1">
                                        <h4 className="title mb-0">Attendee Check-In</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                    <div className="icon text-center rounded-circle me-3">
                                    <FontAwesomeIcon icon={faRectangleAd} />
        </div>
                                    <div className="flex-1">
                                        <h4 className="title mb-0">Easy Event Marketing</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                    <div className="icon text-center rounded-circle me-3">
                                    <FontAwesomeIcon icon={faIdBadge} />
     </div>
                                    <div className="flex-1">
                                        <h4 className="title mb-0">Name Badge Generation</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                    <div className="icon text-center rounded-circle me-3">
                                    <FontAwesomeIcon icon={faPeopleGroup} />
  </div>
                                    <div className="flex-1">
                                        <h4 className="title mb-0">Post Event Networking</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                    <div className="icon text-center rounded-circle me-3">
                                    <FontAwesomeIcon icon={faSquarePollVertical} />
    </div>
                                    <div className="flex-1">
                                        <h4 className="title mb-0">Live Event Polling</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow">
                                    <div className="icon text-center rounded-circle me-3">
                                    <FontAwesomeIcon icon={faMessage} />
 </div>
                                    <div className="flex-1">
                                        <h4 className="title mb-0">Post Event Feed back</h4>
                                    </div>
                                </div>
                            </div>



                            <div className="col-12 mt-4 pt-2 text-center">
                            <Link to="/features" className="btn btn-primary">
                            See More <i className="mdi mdi-arrow-right"></i>
                </Link>
                                        </div>
                        </div>
                    </div>  </div>
            </section>

            <section className="section bg-light">

                <div className="container mt-60">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title">
                                <h4 className="title mb-4" style={{ fontSize: '20px !important' }}>“Human behaviour can be manipulated or inspired. It’s a choice as an event organiser you make. Not making that choice is also a choice you’re making.”<br></br><p style={{ fontSize: ':12px !important' }}>Gathrr Team.</p></h4>
                                <div className="mt-4">
                                <Link to="/pricing" className="btn btn-primary mt-2 me-2">
                                Get Started Now 
                </Link>
                <Link to="/pricing" className="btn btn-outline-primary mt-2">
                Free Trial
                </Link>
                                         </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutUs
