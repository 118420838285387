import {React, useState} from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocation, faPhone, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faUser, faEnvelope,  faContactCard, faFile, faMap,faFileText,faIdCard  ,faComment} from '@fortawesome/free-regular-svg-icons';
import { faFacebookF, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
function Footer() {

    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        phone: '',
        company: '',
        location: '',
        subject: '',
        comments: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData)
        fetch('http://gathrr.radarsofttech.in/:5000/api/email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(data => {
                // Handle the response from the backend
                console.log(data);
            })
            .catch(error => {
                // Handle any errors
                console.error(error);
            });
    };

    return (
        <>

            <section className="section pt-0 textdata">
                <div className="container">
                    <div className="row mt-md-5 pt-md-3 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-center">
                        <div className="col-12 text-center">
                            <div className="section-title">
                                <h4 className="title mb-4">Get the App now !</h4>
                                <p className="text-muted para-desc mx-auto">Create an event with 50 guests, <span className="text-primary fw-bold">test the mobile apps</span>, and try all features for free right in your browser.</p>
                                <div className="mt-4">
                                    <a href="https://apps.apple.com/in/app/gathrr/id1498313428" target="_blank">
                                        <img src="assets/images/app/app.png" className="m-1" height="50" alt="" />
                                    </a>

                                    <a href="https://play.google.com/store/apps/details?id=com.gathrr.radarsoft&hl=en&gl=US" target="_blank">
                                        <img src="assets/images/app/playstore.png" className="m-1" height="50" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="position-relative">
                <div className="shape overflow-hidden text-footer">
                    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="footer-py-60">
                                <div className="row">
                                    <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                        <a href="#" className="logo-footer">
                                            <img src="assets/images/logos.png" height="50" alt="" />
                                        </a>
                                        <p className="mt-4">We believe we have a responsibility to protect our planet and decrease environmental impact in the communities in which we live and work. </p>
                                        <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                                            <li className="list-inline-item">  
                                                      <a href="https://www.facebook.com/Gathrr-1864805263564335" target="_blank" className="rounded"> <FontAwesomeIcon className=" icon-sm icons" icon={faFacebookF} /></a></li>

                                            <li className="list-inline-item"><a href="https://www.instagram.com/gathrr.in/" target="_blank" className="rounded"><FontAwesomeIcon className=" icon-sm icons" icon={faInstagram} /></a></li>

                                            <li className="list-inline-item"><a href="https://twitter.com/gathrr_in" target="_blank" className="rounded"><FontAwesomeIcon className=" icon-sm icons" icon={faTwitter} /></a></li>

                                            <li className="list-inline-item"><a href="https://www.linkedin.com/company/gathrr/" target="_blank" className="rounded"><FontAwesomeIcon className=" icon-sm icons" icon={faLinkedin} /></a></li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 className="footer-head">Company</h5>
                                        <ul className="list-unstyled footer-list mt-4">
                                               <li>
                                                <Link to="/about" className="text-foot">
                                                    <i className="uil uil-angle-right-b me-1"></i> About us
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/features" className="text-foot">
                                                    <i className="uil uil-angle-right-b me-1"></i> Features
                                                </Link>
                                            </li>
                                           <li>
                                                <Link to="/pricing" className="text-foot">
                                                    <i className="uil uil-angle-right-b me-1"></i> Pricing
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 className="footer-head">Useful Links</h5>
                                        <ul className="list-unstyled footer-list mt-4">
                                            <li>
                                            <Link to="/tearms" className="text-foot">
                                                    <i className="uil uil-angle-right-b me-1 "></i> Terms of Services
                                                </Link>
                                                </li>
                                                <li>
                                                <Link to="/privacy" className="text-foot">
                                                    <i className="uil uil-angle-right-b me-1 "></i> Privacy Policy
                                                </Link>
                                                </li>                                        </ul>
                                    </div>

                                    <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                        <h5 className="footer-head">Newsletter</h5>
                                        <p className="mt-4">Sign up and receive the latest tips via email.</p>
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="foot-subscribe mb-3">
                                                        <label className="form-label">Write your email <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faEnvelope} />   <input type="email" name="email" id="emailsubscribe" className="form-control ps-5 rounded" placeholder="Your email : " required />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="d-grid">
                                                        <input type="submit" id="submitsubscribe" name="send" className="btn btn-soft-primary" value="Subscribe" />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="footer-py-30 footer-bar">
                    <div className="container text-center">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="text-sm-start">
                                    <p className="mb-0">© <script>document.write(new Date().getFullYear())</script> Gathrr <i className="mdi mdi-heart text-danger"></i> All Right Reserved.</p>
                                </div>
                            </div>

                            <div className="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <ul className="list-unstyled text-sm-end mb-0">
                                    <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/american-ex.png" className="avatar avatar-ex-sm" title="American Express" alt="" /></a></li>
                                    <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/discover.png" className="avatar avatar-ex-sm" title="Discover" alt="" /></a></li>
                                    <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/master-card.png" className="avatar avatar-ex-sm" title="Master Card" alt="" /></a></li>
                                    <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/paypal.png" className="avatar avatar-ex-sm" title="Paypal" alt="" /></a></li>
                                    <li className="list-inline-item"><a href="javascript:void(0)"><img src="assets/images/payments/visa.png" className="avatar avatar-ex-sm" title="Visa" alt="" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="offcanvas offcanvas-end shadow" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header p-4 border-bottom">
                    <h5 id="offcanvasRightLabel" className="mb-0" >
                        <img src="assets/images/logo.png" height="50" className="light-version" alt="" />
                        <img src="assets/images/logo.png" height="50" className="dark-version" alt="" />
                    </h5>
                    <button type="button" className="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas" aria-label="Close"><i className="uil uil-times fs-4"></i></button>
                </div>
                <div className="offcanvas-body p-4">
                    <div className="row">
                        <div className="col-12">
                            <img src="assets/images/contact.svg" className="img-fluid d-block mx-auto" style={{ maxWidth: '256px' }} alt="" />
                            <div className="card border-0 mt-5" style={{ zIndex: '1' }}>
                                <div className="card-body p-0">
                                    <form method="post" name="myForm1" id="myForm1" action="">
                                        <p id="error-msg" className="mb-0"></p>
                                            <div id="simple-msg"></div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">First Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative text-center">
                                                          <FontAwesomeIcon className=" icon-sm icons" icon={faUser} />
                                                            <input
                                                                name="fname"
                                                                id="fname"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="First Name"
                                                                required=""
                                                                value={formData.fname}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Last Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faUser} />
                                                             <input
                                                                name="lname"
                                                                id="lname"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Last Name"
                                                                required=""
                                                                value={formData.lname}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label"> Email <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faEnvelope} />
                                                             <input
                                                                name="email"
                                                                id="email"
                                                                type="email"
                                                                className="form-control ps-5"
                                                                placeholder="Email"
                                                                required=""
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label">Phone <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faContactCard} />
                                                         <input
                                                                name="phone"
                                                                id="phone"
                                                                type="number"
                                                                className="form-control ps-5"
                                                                placeholder="Phone"
                                                                required=""
                                                                value={formData.phone}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Company Name <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faIdCard} />
                                                       <input
                                                                name="company"
                                                                id="company"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Company Name"
                                                                required=""
                                                                value={formData.company}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Location <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faMap} />
                                                      <input
                                                                name="location"
                                                                id="location"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Location"
                                                                required=""
                                                                value={formData.location}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Subject <span className="text-danger">*</span></label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faFileText} />
                                                       <input
                                                                name="subject"
                                                                id="subject"
                                                                type="text"
                                                                className="form-control ps-5"
                                                                placeholder="Subject"
                                                                required=""
                                                                value={formData.subject}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <label className="form-label">Comments </label>
                                                        <div className="form-icon position-relative">
                                                        <FontAwesomeIcon className=" icon-sm icons" icon={faComment} />
                                                      <input
                                                                name="comments"
                                                                id="comments"
                                                                type="textarea"
                                                                className="form-control ps-5"
                                                                placeholder="Comments"
                                                                required=""
                                                                value={formData.comments}
                                                                onChange={handleChange}
                                                                style={{ paddingLeft: '0' }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="d-grid">
                                                        <button
                                                            type="submit"
                                                            id="submit"
                                                            name="senddata"
                                                            className="btn btn-primary"
                                                        >
                                                            Send Message
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#" onClick="topFunction()" id="back-to-top" className="back-to-top fs-5"><i data-feather="arrow-up" className="fea icon-sm icons align-middle"></i></a>

        </>
    )
}

export default Footer
