import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRouter from './routes/Router';
import Footer from './pages/Footer';
import Header from './pages/Header';

function App() {
  return (
    <Router >
      <>
        <Header />
        <AppRouter />
        <Footer />
      </>
    </Router>
  );
}

export default App;
