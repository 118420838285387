import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, NavbarBrand, Nav, NavItem, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    const mobileThreshold = 768;
    setIsMobileView(window.innerWidth < mobileThreshold);
  };

  useEffect(() => {
    // Check the mobile view initially and add an event listener for window resize
    checkMobileView();
    window.addEventListener('resize', checkMobileView);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const appStoreIconsStyle = {
    display: 'flex',
    justifyContent: isMobileView ? 'center' : 'flex-end',
    alignItems: isMobileView ? 'center' : 'flex-start',

  };
  return (
    <header id="topnav" className="defaultscroll sticky">
      <div className="container">
        <Navbar expand="md" className={isMenuOpen ? 'open' : ''}>
          <NavbarBrand href="/">
            <img src="assets/images/logo.png" height="50" className="logo-light-mode" alt="" />
          </NavbarBrand>

          <div className="menu-extras">
            <div className="menu-item">
              <button
                className={`navbar-toggle ${isMenuOpen ? 'open' : ''}`}
                onClick={toggleMenu}
                style={{
                  border: 'none',
                  outline: 'none',
                  background: 'transparent',
                  padding: 0,
                  cursor: 'pointer',
                  marginLeft: '10px', // Adjust the margin as needed
                }}
              >
                {isMenuOpen ? (
                  <>
                    <div style={{ display: 'flex' }}>
                      <div style={appStoreIconsStyle}>
                        <a href="https://apps.apple.com/in/app/gathrr/id1498313428" className="btn btn-icon btn-light" style={{ marginRight: 3 }}>
                          <img src="assets/images/app/app-store.png" className="avatar avatar-ex-small p-1" alt="" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.gathrr.radarsoft&hl=en&gl=US" className="btn btn-icon btn-light" style={{ marginRight: 3 }}>
                          <img src="assets/images/app/play-store.png" className="avatar avatar-ex-small p-1" alt="" />
                        </a>
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {isMobileView && (
                      <div style={{ display: 'flex' }}>
                        <div style={appStoreIconsStyle}>
                          <a href="https://apps.apple.com/in/app/gathrr/id1498313428" className="btn btn-icon btn-light" style={{ marginRight: 3 }}>
                            <img src="assets/images/app/app-store.png" className="avatar avatar-ex-small p-1" alt="" />
                          </a>
                          <a href="https://play.google.com/store/apps/details?id=com.gathrr.radarsoft&hl=en&gl=US" className="btn btn-icon btn-light" style={{ marginRight: 3 }}>
                            <img src="assets/images/app/play-store.png" className="avatar avatar-ex-small p-1" alt="" />
                          </a>
                        </div>
                        <div className="lines">
                          <span style={{ border: 'none' }}></span>
                          <span style={{ border: 'none' }}></span>
                          <span style={{ border: 'none' }}></span>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </button>
            </div>
          </div>

          {isMenuOpen && (
            <Collapse isOpen={isMenuOpen} navbar>
              <Nav className="ml-auto navigation-menu" navbar>
                <NavItem>
                  <Link to="/" className="nav-link" onClick={toggleMenu}>
                    Home
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/about" className="nav-link" onClick={toggleMenu}>
                    About us
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/features" className="nav-link" onClick={toggleMenu}>
                    Features
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/pricing" className="nav-link" onClick={toggleMenu}>
                    Pricing
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/contact" className="nav-link" onClick={toggleMenu}>
                    Contact us
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          )}

          {!isMenuOpen && (
            <Collapse isOpen={isMenuOpen} navbar>
              <Nav className="ml-auto navigation-menu" navbar>
                <NavItem>
                  <Link to="/" className="nav-link" onClick={toggleMenu}>
                    Home
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/about" className="nav-link" onClick={toggleMenu}>
                    About us
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/features" className="nav-link" onClick={toggleMenu}>
                    Features
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/pricing" className="nav-link" onClick={toggleMenu}>
                    Pricing
                  </Link>
                </NavItem>
                <NavItem>
                  <Link to="/contact" className="nav-link" onClick={toggleMenu}>
                    Contact us
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
          )}

          {!isMenuOpen && (
            <ul className="buy-button list-inline mb-0 d-none d-md-inline" style={{ marginLeft: 'auto' }}>
              <li className="list-inline-item mb-0">
                <a href="https://apps.apple.com/in/app/gathrr/id1498313428" className="btn btn-icon btn-light">
                  <img src="assets/images/app/app-store.png" className="avatar avatar-ex-small p-1" alt="" />
                </a>
              </li>

              <li className="list-inline-item mb-0">
                <a href="https://play.google.com/store/apps/details?id=com.gathrr.radarsoft&hl=en&gl=US" className="btn btn-icon btn-light">
                  <img src="assets/images/app/play-store.png" className="avatar avatar-ex-small p-1" alt="" />
                </a>
              </li>
            </ul>
          )}
        </Navbar>
      </div>
    </header>
  );
}

export default Header;
